@import "./variables";

@font-face {
  font-family: 'Graphik';
  src: url(/home/runner/work/rezonence/rezonence/bazel-root/201956035e49410d535cb2daf92de3a0/execroot/rezonence/bazel-out/k8-fastbuild/bin/freewall-creator/src/./assets/fonts/Graphik-Regular.woff2) format("truetype");
  font-weight: 400;
}


html, body * {
  font-family: Graphik,Helvetica,Arial,sans-serif;
}

button.mat-raised-button, button.mat-flat-button, a.mat-stroked-button, a.mat-raised-button, a.mat-flat-button, a.mat-stroked-button {
  border-radius: 3vmin;
}

html body {
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
}

.fullscreen {
  width: 100%;
  height: 100%;
}


span.mat-button-wrapper {
  display: inline-block;
}

router-outlet.router-flex + * {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}


.arrow-up {
  width: 0;
  height: 0;
  border-left: $up-arrow-width solid transparent;
  border-right: $up-arrow-width solid transparent;
  border-bottom: $margin-medium solid white;
}

.yg-bright {
  background-image: image-set(
    url('/home/runner/work/rezonence/rezonence/bazel-root/201956035e49410d535cb2daf92de3a0/execroot/rezonence/bazel-out/k8-fastbuild/bin/freewall-creator/src/./assets/you-gov-gradient-03-16-x-9-rgb.jpg') 1x,
    url('/home/runner/work/rezonence/rezonence/bazel-root/201956035e49410d535cb2daf92de3a0/execroot/rezonence/bazel-out/k8-fastbuild/bin/freewall-creator/src/./assets/you-gov-gradient-03-16-x-9-rgb@2x.jpg') 2x,
    url('/home/runner/work/rezonence/rezonence/bazel-root/201956035e49410d535cb2daf92de3a0/execroot/rezonence/bazel-out/k8-fastbuild/bin/freewall-creator/src/./assets/you-gov-gradient-03-16-x-9-rgb@3x.jpg') 3x
  )
}

.full-screen-modal {
  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}
